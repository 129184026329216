export const COUNTRIES = [
  'Albanien',
  'Belgien',
  'Bulgarien',
  'Deutschland',
  'Dänemark',
  'Estland',
  'Finnland',
  'Frankreich',
  'Griechenland',
  'Irland',
  'Italien',
  'Kroatien',
  'Lettland',
  'Litauen',
  'Luxemburg',
  'Malta',
  'Moldau',
  'Montenegro',
  'Niederlande',
  'Nordmazedonien',
  'Österreich',
  'Polen',
  'Portugal',
  'Rumänien',
  'Schweden',
  'Schweiz',
  'Serbien',
  'Slowakei',
  'Slowenien',
  'Spanien',
  'Tschechien',
  'Türkei',
  'Ukraine',
  'Ungarn',
  'Vereinigtes Königreich',
  'Zypern',
];

export const STATES_GERMANY = [
  'Baden-Württemberg',
  'Bayern',
  'Berlin',
  'Brandenburg',
  'Bremen',
  'Hamburg',
  'Hessen',
  'Mecklenburg-Vorpommern',
  'Niedersachsen',
  'Nordrhein-Westfalen',
  'Rheinland-Pfalz',
  'Saarland',
  'Sachsen',
  'Sachsen-Anhalt',
  'Schleswig-Holstein',
  'Thüringen',
];
