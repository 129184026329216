import { useState } from 'react';
import {
  Box, Button, Collapse as Transition, Grid, Paper, Skeleton, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import Select from '@components/form/Select';
import { useDispatch, useSelector } from '@store';
import { selectCurrentTagRoot, setTagRoot } from '@modules/organizations/state/slices/organization-tag-root';
import { useTranslation } from 'react-i18next';
import { useIFrameViewer } from '@components/IFrameViewer';

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'inline-block',
  bottom: theme.spacing(-1),
  zIndex: 1,
}));

const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ToggleButtonContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(-2),
}));

export default function MapLegend(props) {
  const { legend, collapsed, position } = props;
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(!collapsed);
  const dispatch = useDispatch();
  const { tag } = useSelector(selectCurrentTagRoot);
  const { openIFrameViewer } = useIFrameViewer();

  const handleToggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  const handleChangeTagRoot = (event) => {
    dispatch(setTagRoot(event.target.value));
  };

  const handleOpenExternalLink = (url) => (e) => {
    if (url.startsWith('http')) {
      e.preventDefault();
      e.stopPropagation();
      openIFrameViewer(url);
    }
  };

  return (
    <Container style={position === 'left' ? {
      left: '20px',
    } : {
      right: '20px',
    }}
    >
      <Transition
        collapsedSize={50}
        in={isExpanded}
      >
        <Inner>
          <ToggleButton
            isExpanded={isExpanded}
            onClick={handleToggleExpanded}
            t={t}
          />
          <Paper
            elevation={5}
            sx={{
              position: 'relative',
              alignSelf: 'flex-start',
              flexGrow: 0,
              p: 1,
              pb: 2,
              zIndex: 2,
            }}
          >
            <Select
              fullWidth
              items={TAG_REFERENCES.map((item) => ({
                ...item,
                label: t(`modules.organizations.components.MapLegand.tagReferences.${item.id}`),
              }))}
              labelProp="label"
              onChange={handleChangeTagRoot}
              size="small"
              value={tag}
            />
            <Box mt={1}>
              {!legend?.segments && (
                <Box sx={{ minWidth: 300 }}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Box>
              )}
              <div style={{
                display: 'inline-grid',
                gridTemplateColumns: 'auto auto',
                gridColumnGap: 10,
              }}
              >
                {legend?.segments?.map((segment) => (
                  <div
                    key={segment.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        ml: 0.5,
                        mr: 1,
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: segment.color,
                      }}
                    />
                    <Typography
                      component="div"
                      sx={{ flex: 1, lineHeight: '1rem', mr: 1 }}
                      variant="caption"
                    >
                      {segment.name === 'NO_TAG_FOUND' ? t('modules.organizations.components.MapLegand.noAllocation') : segment.name}
                    </Typography>
                    <Typography component="div" variant="caption">
                      {segment.value.toLocaleString('de-DE', {
                        style: 'percent',
                        minimumFractionDigits: 1,
                      })}
                    </Typography>
                  </div>
                ))}
              </div>
            </Box>
            {tag === 'energy-storage-type' && (
              <Box mt={0.5}>
                <Button
                  component="a"
                  endIcon={<FiExternalLink />}
                  fullWidth
                  href={t('modules.projects.components.MapLegand.categorizationDetailsUrl')}
                  onClick={handleOpenExternalLink(t('modules.projects.components.MapLegand.categorizationDetailsUrl'))}
                  size="small"
                  target="_blank"
                >
                  {t('modules.organizations.components.MapLegand.categorizationDetails')}
                </Button>
              </Box>
            )}
          </Paper>
        </Inner>
      </Transition>
    </Container>
  );
}

function ToggleButton({ t, isExpanded, onClick }) {
  return (
    <ToggleButtonContainer>
      <Button
        endIcon={isExpanded ? <FiChevronDown /> : <FiChevronUp />}
        onClick={onClick}
      >
        {t('modules.organizations.components.MapLegand.legend')}
      </Button>
    </ToggleButtonContainer>
  );
}

const TAG_REFERENCES = [
  {
    id: 'sector',
    label: 'Branche',
  },
  {
    id: 'products',
    label: 'Produkte',
  },
  {
    id: 'services',
    label: 'Dienstleistungen',
  },
  {
    id: 'energy-storage-type',
    label: 'Energiespeichertyp',
  },
];
