import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import RegionFilters from '@components/filters/RegionFilters';
import {
  FiChevronDown,
  FiCode,
  FiTrash2,
  FiX,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ORGANIZATION_TAG_REFERENCES } from '@modules/member/config';
import TagFilter from '@components/filters/TagFilter';
import ResponsiveDrawer from '@components/ResponsiveDrawer';
import BetaTag from '@components/BetaTag';

export default function ExtendedOrgFilters(props) {
  const {
    isFilterOpen,
    setFilterOpen,
    organizationFilters,
    setOrganizationFilters,
  } = props;

  const { t } = useTranslation();
  const [regionExpanded, setRegionExpanded] = useState(false);
  const [tagItemsExpanded, setTagItemsExpanded] = useState(false);
  const [statusExternalExpanded, setStatusExternalExpanded] = useState(false);
  const [externalChangeRequestsExpanded, setExternalChangeRequestsExpanded] = useState(false);

  const handleClose = () => {
    setFilterOpen(false);

    if (!organizationFilters.addressCity
      && !organizationFilters.addressCountry
      && !organizationFilters.addressState) {
      setRegionExpanded(false);
    } else setRegionExpanded(true);
    if (!organizationFilters.tags?.[0]?.tagItemChild) {
      setOrganizationFilters({ ...organizationFilters, tags: [] });
      setTagItemsExpanded(false);
    } else {
      setTagItemsExpanded(true);
    }
    if (!organizationFilters.externalChangeRequests) {
      setExternalChangeRequestsExpanded(false);
    } else setExternalChangeRequestsExpanded(true);
    if (!organizationFilters.currentUser
      && !organizationFilters.assignmentEditState
      && !organizationFilters.assignedUser) {
      setStatusExternalExpanded(false);
    } else setStatusExternalExpanded(true);
    setOrganizationFilters({
      ...organizationFilters,
      tags: organizationFilters.tags.filter((tag) => tag.tagItemChild),
    });
  };

  const handleClearFilters = () => {
    setOrganizationFilters({});
    setRegionExpanded(false);
    setTagItemsExpanded(false);
    setFilterOpen(false);
    setExternalChangeRequestsExpanded(false);
    setStatusExternalExpanded(false);
  };

  const handleCollapse = () => {
    if (regionExpanded || tagItemsExpanded
      || externalChangeRequestsExpanded
      || statusExternalExpanded) {
      setRegionExpanded(false);
      setTagItemsExpanded(false);
      setExternalChangeRequestsExpanded(false);
      setStatusExternalExpanded(false);
    } else {
      setRegionExpanded(true);
      setTagItemsExpanded(true);
      setExternalChangeRequestsExpanded(true);
      setStatusExternalExpanded(true);
    }
  };

  const handleChangeInput = (field, newValue) => {
    const { [field]: e, ...rest } = organizationFilters;

    if (newValue) {
      if (Object.hasOwn(newValue, 'target')) {
        if (Object.hasOwn(newValue?.target, 'checked')) {
          if (newValue.target.checked) {
            setOrganizationFilters(
              { ...organizationFilters, [field]: newValue.target.checked },
            );
          } else {
            setOrganizationFilters(rest);
          }
        }
      } else {
        setOrganizationFilters({ ...organizationFilters, [field]: newValue });
      }
    } else {
      setOrganizationFilters(rest);
    }
  };

  return (
    <ResponsiveDrawer
      anchor="right"
      onClose={handleClose}
      open={isFilterOpen}
    >
      <Box sx={{ mt: '80px', px: 3 }}>
        <Box display="flex" justifyContent="space-between">
          <IconButton onClick={handleClose}>
            <FiX />
          </IconButton>
          <Tooltip
            placement="left"
            title="Alle Filtergruppen ein-/ausblenden"
          >
            <IconButton onClick={handleCollapse}>
              <FiCode />
            </IconButton>
          </Tooltip>
        </Box>
        <Box mt={3}>
          <Accordion
            expanded={regionExpanded}
            onChange={(event, value) => setRegionExpanded(value)}
          >
            <AccordionSummary
              expandIcon={<FiChevronDown />}
            >
              <Typography variant="subtitle2">
                {t('components.filters.RegionFilters.title')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <RegionFilters
                addressCity={organizationFilters?.addressCity}
                addressCountry={organizationFilters?.addressCountry}
                addressState={organizationFilters?.addressState}
                filters={organizationFilters}
                onChange={handleChangeInput}
                setFilters={setOrganizationFilters}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt={3}>
          <Accordion
            expanded={tagItemsExpanded}
            onChange={(event, value) => setTagItemsExpanded(value)}
          >
            <AccordionSummary
              expandIcon={<FiChevronDown />}
            >
              <Typography variant="subtitle2">
                {t('components.filters.tags.title')}
              </Typography>
              <BetaTag sx={{ ml: 1 }} />
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <TagFilter
                allowedTagRefs={ORGANIZATION_TAG_REFERENCES}
                setTagFilters={(filters) => setOrganizationFilters({
                  ...organizationFilters,
                  tags: filters,
                })}
                tagFilters={organizationFilters.tags}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            onClick={handleClearFilters}
            size="small"
            startIcon={<FiTrash2 />}
          >
            {t('components.filters.clear')}
          </Button>
        </Box>
      </Box>
    </ResponsiveDrawer>
  );
}
