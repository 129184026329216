import React from 'react';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  Typography,
  Switch,
} from '@mui/material';
import {
  ORGANIZATION_TYPE_BGCOLORS,
  ORGANIZATION_TYPE_COLORS,
} from '@theme';
import { useTranslation } from 'react-i18next';

export default function OrganizationTypeSelect(props) {
  const {
    id,
    name,
    disabled,
    readOnly,
    value,
    fullWidth,
    size,
    menuProps,
    label,
    error,
    helperText,
    sx,
    showLiquidatedOrgsFilter,
    setLiquidatedOrgsFilter,
    onChange,
    onBlur,
  } = props;
  const { t } = useTranslation();

  const handleChange = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = event.target.value.filter(Boolean).sort();
    onChange(event);
  };

  const selectedCount = value.length + (showLiquidatedOrgsFilter ? 1 : 0);
  const totalCount = OPTIONS.length + 1;
  const allSelected = selectedCount >= totalCount;

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      sx={sx}
    >
      <InputLabel id={`${id}-label`} size={size}>
        {label}
      </InputLabel>
      <MuiSelect
        disabled={disabled}
        label={label}
        labelId={`${id}-label`}
        MenuProps={menuProps}
        multiple
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        readOnly={readOnly}
        renderValue={(selected) => t('modules.organizations.components.OrganizationTypeSelect.selected', {
          selected: selected.length + (showLiquidatedOrgsFilter ? 1 : 0),
          count: OPTIONS.length + 1,
        })}
        size={size}
        value={value}
      >
        <Subheader>
          {t('modules.organizations.components.OrganizationTypeSelect.title')}
        </Subheader>
        {OPTIONS.map(({
          value: xvalue,
          bgColor,
          textColor,
          level,
          isDeprecated,
        }) => (
          <MenuItem key={xvalue} sx={{ py: 0 }} value={xvalue}>
            <Checkbox checked={value.includes(xvalue)} />
            <Typography
              component="div"
              sx={{
                flex: 1,
                bgcolor: bgColor,
                color: textColor,
                textDecoration: isDeprecated ? 'line-through' : 'none',
                ml: level ? level * 2 : 0,
                px: 0.5,
                borderRadius: '4px',
              }}
              variant="subtitle2"
            >
              {t(`enums.organizationType.${xvalue}`)}
            </Typography>
          </MenuItem>
        ))}
        <Subheader>
          {t('modules.organizations.components.OrganizationTypeSelect.liquidation')}
        </Subheader>
        <MenuItem>
          <FormControlLabel
            checked={showLiquidatedOrgsFilter}
            control={<Checkbox />}
            label={t('modules.organizations.components.OrganizationTypeSelect.showLiquidated')}
            onChange={(event) => {
              event.stopPropagation();
              setLiquidatedOrgsFilter(event.target.checked);
            }}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            checked={allSelected}
            control={<Switch />}
            label={t(`modules.organizations.components.OrganizationTypeSelect.${allSelected ? 'deselectAll' : 'selectAll'}`)}
            onChange={(event) => {
              event.stopPropagation();
              const newValue = event.target.checked ? OPTIONS.map((opt) => opt.value) : [];
              setLiquidatedOrgsFilter(event.target.checked);
              onChange({ target: { value: newValue } });
            }}
          />
        </MenuItem>
      </MuiSelect>
      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

function Subheader({ children }) {
  return (
    <>
      <ListSubheader sx={{ lineHeight: '1em', mt: 1 }}>
        {children}
      </ListSubheader>
      <Divider sx={{ mt: 1 }} />
    </>
  );
}

const OPTIONS = [
  {
    value: 'researchInstitution',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.researchInstitution,
    textColor: ORGANIZATION_TYPE_COLORS.researchInstitution,
  },
  {
    value: 'instituteCentralFacilityChair',
    bgColor: '#757575',
    textColor: 'primary.contrastText',
    level: 1,
  },
  {
    value: 'subgroup',
    bgColor: '#E0E0E0',
    level: 2,
  },
  {
    value: 'company',
    bgColor: 'secondary.main',
    textColor: 'secondary.contrastText',
  },
  {
    value: 'location',
    bgColor: 'secondary.light',
    textColor: 'secondary.contrastText',
    level: 1,
  },
  {
    value: 'department',
    bgColor: '#fefadf',
    textColor: 'secondary.contrastText',
    level: 2,
  },
  {
    value: 'federalMinistry',
    bgColor: 'primary.main',
    textColor: 'primary.contrastText',
  },
  {
    value: 'referat',
    bgColor: 'primary.light',
    level: 1,
  },
  {
    value: 'projectSponsor',
    bgColor: 'primary.main',
    textColor: 'primary.contrastText',
  },
  {
    value: 'workUnit',
    bgColor: 'primary.light',
    level: 1,
  },
];
