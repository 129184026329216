import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Hidden,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FiList,
  FiMapPin,
  FiPlus,
  FiSliders,
} from 'react-icons/fi';
import SearchBox from '@components/SearchBox';
import { useRouter } from 'next/router';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { rootPath } from '../module';
import OrganizationTypeSelect from './OrganizationTypeSelect';
import StatisticsBar from './StatisticsBar';

export default function FilterBar(props) {
  const {
    loading,
    currentView,
    isFilterOpen,
    showLiquidatedOrgsFilter,
    organizationFilters,
    organizationTypeFilter,
    organizationTypeFilterFn,
    search,
    setFilterOpen,
    setLiquidatedOrgsFilter,
    setOrganizationTypeFilter,
    setSearchTerm,
    statistics,
  } = props;
  const router = useRouter();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(search);

  let filterLength;
  if (organizationFilters.tags?.[0]?.tagItemChild) {
    filterLength = Object.keys({
      ...organizationFilters,
    }).length > 0 && Object.keys({
      ...organizationFilters,
    }).length;
  } else {
    const { tags, ...rest } = organizationFilters;
    filterLength = Object.keys({
      ...rest,
    }).length > 0 && Object.keys({
      ...rest,
    }).length;
  }

  const handleViewChange = (view) => () => {
    const url = `${rootPath}/${view}`;
    router.push(url);
  };

  const handleToggleFilter = () => {
    setFilterOpen(!isFilterOpen);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(setSearchTerm, 500),
    [setSearchTerm],
  );

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, handleSearch]);

  return (
    <Box pt={1} px={2}>
      <Grid
        alignItems="center"
        container
        spacing={2}
      >
        <Grid item lg={2} md={2} sm={3} xl={1} xs={12}>
          <ButtonGroup fullWidth size="small">
            <Tooltip title={t('modules.organizations.components.FilterBar.showMapOnly')}>
              <Button
                onClick={handleViewChange('map')}
                variant={currentView === 'map' ? 'contained' : 'outlined'}
              >
                <Typography component="div" sx={{ py: 0.42 }}>
                  <FiMapPin style={{ display: 'block' }} />
                </Typography>
              </Button>
            </Tooltip>
            <Hidden mdDown>
              <Tooltip title={t('modules.organizations.components.FilterBar.showMapTable')}>
                <Button
                  onClick={handleViewChange('maplist')}
                  variant={currentView === 'maplist' ? 'contained' : 'outlined'}
                >
                  <Typography
                    component="div"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      py: 0.42,
                    }}
                  >
                    <FiMapPin style={{ display: 'block' }} />
                    <FiPlus style={{ display: 'block' }} />
                    <FiList style={{ display: 'block' }} />
                  </Typography>
                </Button>
              </Tooltip>
            </Hidden>
            <Tooltip title={t('modules.organizations.components.FilterBar.showTableOnly')}>
              <Button
                onClick={handleViewChange('list')}
                variant={currentView === 'list' ? 'contained' : 'outlined'}
              >
                <Typography component="div" sx={{ py: 0.42 }}>
                  <FiList style={{ display: 'block' }} />
                </Typography>
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
        <Grid item lg={2} md={3} sm={9} xl={2} xs={12}>
          <OrganizationTypeSelect
            fullWidth
            label={t('modules.organizations.components.FilterBar.organizationType')}
            labelProp="label"
            onChange={(e) => setOrganizationTypeFilter(e.target.value)}
            setLiquidatedOrgsFilter={setLiquidatedOrgsFilter}
            showLiquidatedOrgsFilter={showLiquidatedOrgsFilter}
            size="small"
            sx={{
              backgroundColor: 'background.paper',
            }}
            value={organizationTypeFilter}
          />
        </Grid>
        <Grid item lg={6} md={5} sm={9} sx={{ flex: 1 }} xl={8} xs={12}>
          <SearchBox
            fullWidth
            onChange={(event) => setSearchValue(event.target.value)}
            size="small"
            value={searchValue}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xl={1} xs={12}>
          <Button
            fullWidth
            onClick={handleToggleFilter}
            size="small"
            startIcon={<FiSliders />}
            variant={filterLength ? 'contained' : 'outlined'}
          >
            {t('shared.filters')}
            {' '}
            {filterLength > 0 && `(${filterLength})`}
          </Button>
        </Grid>
      </Grid>
      <StatisticsBar loading={loading} statistics={statistics} />
    </Box>
  );
}
