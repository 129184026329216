import { Box, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function StatisticsBar(props) {
  const { loading, statistics } = props;
  const { t } = useTranslation();

  if (loading) {
    return (
      <Box mt={0.75}>
        <Typography variant="caption">
          <Skeleton />
        </Typography>
      </Box>
    );
  }

  return (
    <Box mb={0.25} ml={1} mt={0}>
      <Typography
        dangerouslySetInnerHTML={{
          __html: t('modules.organizations.components.StatisticsBar.text', {
            totalProjects: statistics.total,
            totalLocations: statistics.locations,
          }),
        }}
        variant="caption"
      />
    </Box>
  );
}
