import {
  Autocomplete as MuiAutocomplete,
  TextField,
} from '@mui/material';

export default function Autocomplete(props) {
  const {
    id,
    name,
    disabled,
    readOnly,
    value,
    fullWidth,
    size,
    label,
    error,
    helperText,
    sx,
    items,
    onChange,
    onBlur,
  } = props;

  return (
    <MuiAutocomplete
      disabled={disabled}
      freeSolo
      fullWidth={fullWidth}
      id={id}
      inputValue={value}
      name={name}
      onInputChange={onChange}
      options={items}
      readOnly={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          onBlur={onBlur}
        />
      )}
      size={size}
      sx={sx}
    />
  );
}
