import { Drawer } from '@mui/material';

export default function ResponsiveDrawer(props) {
  const {
    ...otherProps
  } = props;

  return (
    <Drawer
      {...otherProps}
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
        },
      }}
    />
  );
}
