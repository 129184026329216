import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import {
  FiSearch,
  FiX,
} from 'react-icons/fi';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

export default function SearchBox(props) {
  const {
    value,
    sx,
    InputProps,
    onChange,
  } = props;
  const { t } = useTranslation();

  const isEmptyValue = isNil(value) || isEmpty(value);
  const handleClear = () => {
    onChange({
      target: {
        value: '',
      },
    });
  };

  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <FiSearch />
          </InputAdornment>
        ),
        endAdornment: !isEmptyValue && (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={handleClear}
            >
              <FiX />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      placeholder={t('components.SearchBox.search')}
      size="small"
      sx={{
        ...sx,
        backgroundColor: 'background.paper',
      }}
    />
  );
}

SearchBox.propTypes = {
  ...TextField.propTypes,
};

SearchBox.defaultProps = {
  ...TextField.defaultProps,
};
