/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Select as MuiSelect,
  Tooltip,
  Typography,
} from '@mui/material';
import Select from '@components/form/Select';
import {
  FiPlusCircle,
  FiTrash2,
} from 'react-icons/fi';
import {
  useLazyQuery,
  useQuery,
} from '@lib/hooks';
import clsx from 'clsx';
import { gql } from '@apollo/client';
import {
  TreeItem,
  useTreeItemState,
} from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function TagFilter(props) {
  const {
    allowedTagRefs,
    tagFilters,
    setTagFilters,
  } = props;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!tagFilters || !tagFilters.length) setTagFilters([getNewItemTemplate()]);
  }, [tagFilters]);

  const { data: rootTags } = useQuery(FETCH_ROOT_TAGS, {
    variables: {
      referenceIds: allowedTagRefs.map((item) => item.id),
    },
  });

  const handleAddItem = () => {
    setTagFilters([...tagFilters, getNewItemTemplate()]);
  };

  const handleRemoveItem = (index) => {
    let newItems = tagFilters.filter((_, i) => i !== index);
    if (newItems.length === 0) newItems = [getNewItemTemplate()];
    setTagFilters(newItems);
  };

  return (
    <>
      {tagFilters && tagFilters.map((tagFilter, i) => (
        <RowItem
          key={i}
          handleRemoveItem={handleRemoveItem}
          i18n={i18n}
          index={i}
          rootTags={rootTags}
          setTagFilters={setTagFilters}
          t={t}
          tagFilter={tagFilter}
          tagFilters={tagFilters}
        />
      ))}
      <Box mt={1}>
        <Tooltip
          placement="right"
          title={t('components.filters.TagFilter.addLink')}
        >
          <IconButton
            onClick={handleAddItem}
          >
            <FiPlusCircle />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}

function RowItem(props) {
  const {
    handleRemoveItem,
    i18n,
    index,
    rootTags,
    setTagFilters,
    t,
    tagFilter,
    tagFilters,
  } = props;
  const [childSelectOpen, setChildSelectOpen] = useState(false);

  const [getTagItemWithChildren, { data: childTags }] = useLazyQuery(FETCH_CHILD_TAGS);

  useEffect(() => {
    if (tagFilter.tagItemRoot) {
      getTagItemWithChildren({
        variables: {
          tagItemId: tagFilter.tagItemRoot,
        },
      });
    }
  }, [tagFilter.tagItemRoot]);

  const handleChangeItemValue = (item, itemIndex, key, value) => {
    let childDisplayValue = '';
    const newItem = { ...item };
    newItem[key] = value;

    if (key === 'tagItemChild') {
      if (childTags) {
        childDisplayValue = findTagName(
          value,
          childTags.tagItem.data.attributes.child_items.data,
          { i18n },
        ) ?? value;
        newItem.tagItemChildDisplayValue = childDisplayValue;

        // Get full tree from selection
        newItem.fullTreeFromSelection = [];
        findAllChildren(
          value,
          childTags.tagItem.data.attributes.child_items.data,
          newItem.fullTreeFromSelection,
        );
      }
    }
    setTagFilters(Object.assign([], tagFilters, { [itemIndex]: newItem }));

    if (key === 'tagItemRoot') {
      setTagFilters(Object.assign([], tagFilters, {
        [itemIndex]: {
          ...newItem,
          tagItemChild: '',
          tagItemChildDisplayValue: '',
        },
      }));
    }
  };

  return (
    <Box
      key={tagFilter.id}
      alignItems="center"
      display="flex"
      sx={{ mb: 2 }}
    >
      <Select
        items={rootTags?.tagItems.data ?? []}
        label={t('components.filters.TagFilter.tags')}
        labelProp={`attributes.name_${i18n.language}`}
        onChange={(event) => handleChangeItemValue(tagFilter, index, 'tagItemRoot', event.target.value)}
        size="small"
        sx={{ minWidth: 140 }}
        value={tagFilter.tagItemRoot}
      />
      <Box sx={{ flex: 1, ml: 1, minWidth: 100 }}>
        <FormControl fullWidth>
          <InputLabel size="small">
            {t('components.filters.TagFilter.selectValue')}
          </InputLabel>
          <MuiSelect
            disabled={!tagFilter.tagItemRoot}
            fullWidth
            label={t('components.filters.TagFilter.selectValue')}
            MenuProps={{
              PaperProps: {
                sx: {
                  p: 0,
                  width: 500,
                  height: 300,
                },
              },
            }}
            onClose={() => setChildSelectOpen(false)}
            onOpen={() => setChildSelectOpen(true)}
            open={childSelectOpen}
            renderValue={(value) => value}
            size="small"
            value={tagFilter.tagItemChildDisplayValue}
          >
            <SimpleTreeView
              getItemId={(item) => item.id}
              onItemSelectionToggle={(event, newValue) => {
                handleChangeItemValue(tagFilter, index, 'tagItemChild', newValue);
              }}
            >
              {renderTree(childTags?.tagItem.data.attributes.child_items.data ?? [], { i18n })}
            </SimpleTreeView>
          </MuiSelect>
        </FormControl>
      </Box>
      <Tooltip
        title={t('components.filters.TagFilter.removeLink')}
      >
        <IconButton
          color="error"
          onClick={() => handleRemoveItem(index)}
          size="small"
          sx={{ ml: 1 }}
        >
          <FiTrash2 />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

// eslint-disable-next-line react/display-name
const CustomContent = React.forwardRef((props, ref) => {
  const {
    itemId,
    classes,
    className,
    label,
    color,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItemState(itemId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    event.stopPropagation();
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onClick={handleExpansionClick}
      onKeyDown={() => {
      }}
      onMouseDown={handleMouseDown}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
       jsx-a11y/no-static-element-interactions  */}
      <div className={classes.iconContainer}>
        {icon}
      </div>
      {color && (
        <Box
          sx={{
            mx: 0.5,
            width: 10,
            height: 10,
            flex: '0 0 10px',
            borderRadius: '50%',
            backgroundColor: color,
          }}
        />
      )}
      <Typography
        className={classes.label}
        component="div"
        onClick={handleSelectionClick}
      >
        {label}
      </Typography>
    </div>
  );
});

function getNewItemTemplate() {
  return {
    tagItemRoot: '',
    tagItemChild: '',
    tagItemChildDisplayValue: '',
    fullTreeFromSelection: [],
  };
}

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  itemId: PropTypes.string.isRequired,
  /**
   * The color of the node.
   */
  color: PropTypes.string,
};

function CustomTreeItem(props) {
  const { color } = props;

  return (
    <TreeItem
      ContentComponent={CustomContent}
      ContentProps={{ color }}
      {...props}
    />
  );
}

function renderTree(items, context) {
  return items.map((item) => (
    <CustomTreeItem
      key={item.id}
      color={item.attributes.color}
      itemId={item.id}
      label={item.attributes[`name_${context.i18n.language}`]}
    >
      {renderTree(item.attributes.child_items?.data ?? [], context)}
    </CustomTreeItem>
  ));
}

function findTagName(id, items, context) {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    if (item.id === id) {
      return item.attributes[`name_${context.i18n.language}`];
    }

    const name = findTagName(id, item.attributes.child_items?.data ?? [], context);

    if (name) return name;
  }

  return null;
}

function findAllChildren(id, items, idlist = [], includeChildren = false) {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    if (item.id === id) {
      idlist.push(item.id);
      findAllChildren(id, item.attributes.child_items?.data ?? [], idlist, true);
    } else if (includeChildren) {
      idlist.push(item.id);
    }

    findAllChildren(id, item.attributes.child_items?.data ?? [], idlist, includeChildren);
  }
}

const FETCH_ROOT_TAGS = gql`
  query Fetch($referenceIds: [String]!) {
    tagItems(
      filters: {
        referenceId: { in: $referenceIds }
      },
      pagination: { limit: 100 },
      sort: ["index:asc", "name_de:asc"]
    ){
      data{
        id
        attributes{
          name_de
          name_en
          referenceId
          index
          color
        }
      }
    }
  }
`;

function fetchChildTags(level = 0) {
  if (level === 10) return 'name_de';

  return `
      child_items {
        data {
          id
          attributes {
            referenceId
            index
            name_de
            name_en
            color
            ${fetchChildTags(level + 1)}
          }
        }
      }
    `;
}

const FETCH_CHILD_TAGS = gql`
  query FetchTagItem($tagItemId: ID!) {
    tagItem(id: $tagItemId) {
      data {
        id
        attributes {
          index
          referenceId
          name_de
          name_en
          color
          ${fetchChildTags()}
        }
      }
    }
  }
`;
